.custom-file {
    cursor: pointer;
    
    &-label, &-input {
        cursor: pointer;
    }
}

.flash-success {
    @extend .alert;
    @extend .alert-success;
}

form fieldset.form-group legend {
    font-size: 16px;
    font-weight: 400;
    text-transform: initial;
}
// .form-signin {
//     width: 100%;
//     max-width: 330px;
//     padding: 15px;
//     margin: auto;
// }

// .form-signin .checkbox {
//     font-weight: 400;
// }

// .form-signin .form-control {
//     position: relative;
//     box-sizing: border-box;
//     height: auto;
//     padding: 10px;
//     font-size: 16px;
// }

// .form-signin .form-control:focus {
//     z-index: 2;
// }

// .form-signin {
//     input[type="email"] {
//         margin-bottom: -1px;
//         border-bottom-right-radius: 0;
//         border-bottom-left-radius: 0;
//     }
//     input[type="password"] {
//         margin-bottom: 10px;
//         border-top-left-radius: 0;
//         border-top-right-radius: 0;
//     }
// }

.form-profile {
    // max-width: 100%;
    // width: 350px;
    // margin: 0 auto;
    // background-color: #f1f1f1;
    padding: 0 2rem 1rem 2rem;
}

.custom-control-label {
    cursor: pointer;
    font-weight: bold;
    padding-left: 15px;
    &:hover {
        color: #000;
    }
}

.custom-checkbox .custom-control-input:checked~.custom-control-label {
    color: $green;
}

.custom-control-label::before,
.custom-control-label::after {
    top: 3px;
    width: 1.25rem;
    height: 1.25rem;
}

.custom-control-label:before {
    background-color: $gray-400;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: $green;
}

div[id^=amm_contact_] {
    display: flex;
}


// .form-label-group {
//     position: relative;
//     // margin-bottom: 1rem;
// }

// .form-label-group> input:not(.no-label),
// .form-label-group>label {
//     height: 3.125rem;
//     padding: .75rem;
// }

// .form-label-group>label {
//     position: absolute;
//     top: 0;
//     left: 0;
//     display: block;
//     width: 100%;
//     margin-bottom: 0;
//     /* Override default `<label>` margin */
//     line-height: 1.5;
//     color: #495057;
//     pointer-events: none;
//     cursor: text;
//     /* Match the input under the label */
//     border: 1px solid transparent;
//     border-radius: .25rem;
//     transition: all .1s ease-in-out;
// }

// .form-label-group input:not(.no-label)::-webkit-input-placeholder {
//     color: transparent;
// }

// .form-label-group input:not(.no-label):-ms-input-placeholder {
//     color: transparent;
// }

// .form-label-group input:not(.no-label)::-ms-input-placeholder {
//     color: transparent;
// }

// .form-label-group input:not(.no-label)::-moz-placeholder {
//     color: transparent;
// }

// .form-label-group input:not(.no-label)::placeholder {
//     color: transparent;
// }

// .form-label-group input:not(.no-label):not(:placeholder-shown) {
//     padding-top: 1.25rem;
//     padding-bottom: .25rem;
// }

// .form-label-group input:not(.no-label):not(:placeholder-shown)~label {
//     padding-top: .25rem;
//     padding-bottom: .25rem;
//     font-size: 12px;
//     color: #777;
// }

/* Fallback for Edge
-------------------------------------------------- */
// @supports (-ms-ime-align: auto) {
//     .form-label-group>label {
//         display: none;
//     }

//     .form-label-group input::-ms-input-placeholder {
//         color: #777;
//     }
// }

/* Fallback for IE
-------------------------------------------------- */
// @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
//     .form-label-group>label {
//         display: none;
//     }

//     .form-label-group input:-ms-input-placeholder {
//         color: #777;
//     }
// }

.has-more {
    list-style: none;
    counter-reset: my-awesome-counter;
    margin-bottom: 10rem;
    
    li {
        display: block;
        padding: 1rem 0;
        position: relative;
        border-bottom: 5px solid #999;
        padding: 1rem;
    }

    // li:nth-child(even){
    //     background-color: #fafafa;
    // }


    
    
    li {
        counter-increment: my-awesome-counter;
        &::before {
            content: counter(my-awesome-counter);
            background-color: $blue;
            display: block;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            color: #fff;
            font-weight: bold;
            font-size: 14px;
            line-height: 25px;
            text-align: center;
            position: absolute;
            top: 55px;
            left: -30px;
        }
    }

}

.status-tool-bar {
    position: fixed; 
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    background-color: $green;
    z-index: 9999;
}

body.dev .status-tool-bar{
    bottom: 36px;
}

fieldset.form-group {
    border: 1px solid #ccc;
    padding: 10px;

    legend {
        text-transform: uppercase;
        font-size: 1.2rem;
        display: inline-block;
        width: auto;
        padding: 0 10px;
    }

    .form-group {
        margin-bottom: 0.2rem;
    }

    input, select {
        padding: 2px 5px;
        height: auto;
        font-size: 0.95rem;
    }

    label {
        margin-bottom: 2px;
        font-size: 0.95rem;
    }
}

.add-wrapper {
    padding-top: 1rem;
    text-align: center;
}

label.required {
    display: inline-block;

    &::after {
        content: " *";
        color: rgba($red, 0.7);
    }
}

.invalid-feedback {
    position: absolute;
    top: 90%;
}

.field-group, .form-group {
    position: relative;
}

.col-form-labelÂ {
    font-weight: bold;
}

.select2 {
    width: 100% !important;
}

.disabled .form-control {
    background-color: #e9ecef;
    opacity: 1;
    padding: 2px 5px;
    height: auto;
    min-height: 28px;
    font-size: 0.95rem;
    // border-radius: 0;
    border-color: #e9ecef;
}

.button-submit-data {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(#666, 0.3);
    padding: 1rem;
    text-align: center;

    a {
        background-color: $green;
        padding: 0.5rem 3rem;
        color: #fff;
        margin: 0 auto;
        border-radius: 7px;
        // width: 100%;
        display: inline-block;
        text-align: center;
    }
}

form[name=fos_user_change_password_form] {
    input[type=submit] {
        @extend .btn;
        @extend .btn-primary;
    }
}

.recaptcha {
    .invalid-feedback {
        display: block;
        position: relative;
        top: 0;
    }
}