a {
    color: $blue;
    text-decoration: none;
}

.bg-blue {
    background-color: $blue;
}

.list-style-none {
    padding-left: 0;
    list-style: none;
}

h1 {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2rem;
    text-transform: uppercase;
    position: relative;
    color: $blue;

    // &::after {
    //     content: "";
    //     width: 100px;
    //     height: 1px;
    //     background-color: #ccc;
    //     left: 50%;
    //     position: absolute;
    //     bottom: -10px;
    //     transform: translateX(-50%);
    // }
}

h2 {
    text-transform: uppercase;
    font-size: 1.3rem;
    font-weight: bold;
    color: #555;
}

.animated {
    animation-delay: 0;
    animation-duration: 0.5s;
    animation-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
    animation-fill-mode: both;
}

@keyframes zoomIn {
    from {
        opacity: 0;
        transform: scale3d(0.7, 0.7, 0.7);
    }

    50% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.zoomIn {
    animation-name: zoomIn;
}

.fadeIn {
    animation-name: fadeIn;
}