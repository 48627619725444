.btn-green {
    @include button-variant($green, $green,darken($green, 7.5%), $hover-border: darken($green, 10%), $active-background: darken($green, 10%), $active-border: darken($green, 12.5%));
    color: #fff;
}

.btn-copy {
    position: relative;
    margin-top: 15px;

    &::after {
        content: "\f178";
        color: $blue;
        font-weight: 1.6rem;
        line-height: 0;
        font-family: FontAwesome;
        position: absolute;
        left: 0;
        bottom: -15px;
        right: 0;
    }
}

.btn-success {
    color: #fff;
}
.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Parcourir";
}