// .sep {
//     width: 40px;
//     height: 1px;
//     background-color: $green;
// }

.text-information-user {
    color: #333;
    line-height: 1.2rem;
    font-size: 1.0rem;
}

.table .thead-dark th {
    background-color: #555;
}

#validate_data {
    color: #fff;
}

.text-intro {
    text-align: justify;
}