.logo-med {
    width: 50px;
    // background-color: rgba(#fff, 0.05);
    background-color: rgba($green, 1);
    display: block;
    height: 50px;
    border-radius: 50%;
    position: relative;
    // overflow: hidden;
    text-align: center;
    margin-right: 1rem;

    svg {
        width: 30px;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.navbar-brand { 
    line-height: 1.3rem;
    font-size: 18px;
    font-weight: 400;
}

#top-bar {
    background-color: #f1f1f1;
}

.img-logo {
    max-width: 250px;
}

.navbar-fmvo {
    padding-bottom: 5px;

    .navbar-nav {
        padding-bottom: 22px;
        position: relative;

        &::after {
            content: "";
            z-index: 0;
            position: absolute;
            right: 0;
            left: 0;
            height: 1px;
            bottom: 10px;
            background-color: rgba(#fff, 0.25);
        }

        .nav-link {
            color: #fff;
            // display: inline-flex;
            padding: 3px 8px;
            margin: 0 10px;
            border-radius: 5px;
            font-size: 13px;
            text-transform: uppercase;
            font-weight: 300;
            position: relative;
            text-align: center;
            transition: all 0.2s ease;
            display: flex;
            height: 100%;
            align-items: center;

            &.status::after {
                content: "\f00c";
                z-index: 999;
                font-family: FontAwesome;
                position: absolute;
                color: #aaa;
                text-align: center;
                line-height: 20px;
                bottom: -21px;
                left: 50%;
                width: 20px;
                height: 20px;
                background-color: #fff;
                transform: translateX(-50%);
                border-radius: 50%;
            }
            &.status-success::after {
                color: #fff;
                background-color: $green;
            }

            &.status-pending::after {
                color: #fff;
                background-color: $yellow;
            }
            
            &.status-error::after {
                content: "\f00d";
                color: #fff;
                background-color: $red;
            }

            &:hover {
                background-color: rgba(#000, 0.2);
                color: #fff;
            }
        }


        .active .nav-link {
            background-color: rgba(#fff, 0.25);
        }

    }
}

.claim {
    padding-top: 5px;
    font-size: 26px;
    line-height: 32px;
    //font-style: italic;
    color: #2a71b8;
}

.user-info {
    font-size: 13px;
}

@include media-breakpoint-up(md) {

    .menu-wrapper-sticky {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        width: 100%;
        height: 80px;
        z-index: 999;
    }
}